/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import styles from './index.module.scss';

const PageLayout = ({
  component: Component,
  ...props
}) => (
  <Route
    {...props}
    render={(matchProps) => (
      <div className={styles.root}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.inner}>
              <img src="/grin-logo.svg" alt="Grin" className={styles.logo} />
            </div>
          </div>
          <div className={styles.body}>
            <Component {...matchProps} />
          </div>
          <div className={styles.footer}>
            <div className={styles.inner}>
              <div className={styles.info}>
                © Grin Chile SpA
              </div>
              <div className={styles.contact}>
                <a href={`https://api.whatsapp.com/send?phone=+56927488636&text=${encodeURIComponent('Hola, tengo una consulta sobre la compra de scooters Grin')}`}>
                  <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: 24 }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  />
);

PageLayout.propTypes = {
  component: PropTypes.any.isRequired,
};

export default PageLayout;

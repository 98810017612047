/* eslint-disable max-len */
import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {faTag} from '@fortawesome/free-solid-svg-icons';
import pageStyles from '../styles/page.module.scss';

const HomeSection = () => (
  <div>
    <div className={[pageStyles.section, pageStyles.sectionHero].join(' ')}>
      <div className={pageStyles.inner}>
        <div className={pageStyles.sectionTitleMark}>
          ¡Compra un Scooter Grin y muévete libremente!
        </div>
        <div className={pageStyles.description}>
          Ágil, rápido y sin atascos. Recorre la ciudad sin preocupaciones.
          ¡Muévete a tu ritmo, a donde quieras!
        </div>
      </div>
    </div>

    <div className={[pageStyles.section, pageStyles.sectionProduct].join(' ')}>
      <div className={pageStyles.inner}>
        <div
          className={[pageStyles.product, pageStyles.withImageLeft].join(' ')}
        >
          <div className={pageStyles.image}>
            <img src="/images/skick-1.png" alt="Skick" />
          </div>
          <div className={pageStyles.info}>
            <div className={pageStyles.title}>
              Scooter electrico Grin Ninebot Segway 1.1
            </div>
            <div className={pageStyles.details}>
              <ul>
                <li>Velocidad maxima 25 km/h</li>
                <li>Distancia maxima de 25 km</li>
                <li>Luz delantera y trasera Led</li>
                <li>Máximo angulo de subida 10 grados</li>
                <li>Peso maximo conductor 100 Kg</li>
                <li>Resistente al agua bajo norma IP54</li>
                <li>Peso del scooter: 12.5 kg</li>
                <li>Alto: 113 cm. Ancho: 43 cm. Largo: 102 cm</li>
                <li>
                  Aplicacion de Segway (disponible para IOS y Android) con la
                  cual puedes ajustar las luces,tener un diagnostico del estado
                  del vehiculo, controlar la velocidad maxima y obtener
                  estadisticas
                </li>
              </ul>
            </div>
            <div className={pageStyles.original_price}>$ 450.000</div>
            <div className={pageStyles.price}>
              <div className={pageStyles.v}>$ 299.990</div>
              <span className={pageStyles.discount}>
                <FontAwesomeIcon icon={faTag} style={{marginRight: 8}} />
                -33%
              </span>
            </div>
            <div className={pageStyles.cta}>
              <a
                href={`https://api.whatsapp.com/send?phone=+56927488636&text=${encodeURIComponent(
                  'Hola, tengo una consulta sobre la compra del scooter Grin Ninebot Segway 1.1',
                )}`}
              >
                <div className={pageStyles.contactButton}>
                  <FontAwesomeIcon icon={faWhatsapp} style={{fontSize: 28}} />
                  <span>Estoy interesado</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={[pageStyles.section].join(' ')}>
      <div className={pageStyles.inner}>
        <div className={pageStyles.sectionTitle}>
          ¿Tienes dudas o consultas?
        </div>
        <div className={pageStyles.sectionSubtitle}>
          Contáctanos y te ayudaremos.
        </div>
        <div className={pageStyles.sectionContent}>
          <div style={{textAlign: 'center'}}>
            <a
              href={`https://api.whatsapp.com/send?phone=+56927488636&text=${encodeURIComponent(
                'Hola, tengo una consulta sobre la compra de scooters Grin',
              )}`}
            >
              <div className={pageStyles.contactButton}>
                <FontAwesomeIcon icon={faWhatsapp} style={{fontSize: 32}} />
                <span>Contáctanos</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HomeSection;

import React from 'react';
import ReactDOM from 'react-dom';
// import {createRoot} from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import moment from 'moment';
import numeral from 'numeral';
import 'numeral/locales/es';

import Routes from './routes';
import '@react-page/editor/lib/index.css';
import * as serviceWorker from './serviceWorker';
import './index.css';

import 'moment/locale/es';

moment.locale('es');
numeral.locale('es');

console.warn = function () { };

if (process.env.NODE_ENV === 'production') {
  console.log = function () { };
  Sentry.init({
    dsn: 'https://664ac28657194ca29de7db1789360c00@o562983.ingest.sentry.io/5702333',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Routes />,
  document.getElementById('root'),
);

/*
createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <Routes />
  </Provider>,

);
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
